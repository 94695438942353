import { EyeSlashIcon } from "@heroicons/react/24/solid"
import { useColorScheme } from "hooks/useColorScheme"
import { trpc } from "lib/trpc/trpc"
import { Tooltip } from "./Tooltip"
import { useBlocksUserStore } from "@/hooks/useStore"

export interface BanUserButtonProps {
	id: number
}

export default function BanUserButton({ id }: BanUserButtonProps) {
	const isModerator = useBlocksUserStore((state) => state.current?.isModerator)
	const {
		data: isShadowBanned,
		isLoading,
		refetch,
	} = trpc.user.getShadowBannedStatus.useQuery(id, { enabled: !!isModerator })

	const { mutateAsync } = trpc.user.setShadowBannedStatus.useMutation()
	const toggleBan = async () => {
		await mutateAsync({ id, isShadowBan: !isShadowBanned })
		refetch()
	}
	const isDarkMode = useColorScheme()

	if (isLoading || !isModerator) {
		return <></>
	}
	return (
		<Tooltip side="bottom" content={`${isShadowBanned ? "Remove Shadow Ban" : "Enforce Shadow Ban"}`}>
			<button
				onClick={toggleBan}
				id="ban-user-btn"
				className="h-11 w-11 rounded-full bg-black bg-opacity-20 p-3 hover:bg-foil dark:bg-white">
				{isShadowBanned ? (
					<EyeSlashIcon fill={isDarkMode ? "black" : "white"} />
				) : (
					<EyeSlashIcon fill={isDarkMode ? "black" : "white"} />
				)}
			</button>
		</Tooltip>
	)
}
