import { Linky } from "components/Linky"
import { NextSeo } from "next-seo"
import Head from "next/head"
import { useRouter } from "next/router"

/** Default 404 page */
export default function Custom404(props) {
	const router = useRouter()

	// console.log(router.pathname, props)
	/** If embed path, render embed 404 */
	if (router.pathname.match(/^\/embed\/.+/)) {
		return <Embed404 />
	}

	return <ErrorPage code={404} />
}

export function ErrorPage({ code }) {
	return (
		<>
			<Head>
				<meta name="robots" content="noindex" />
			</Head>
			<NextSeo title={`Page not found`} />
			<div className="mt-32 flex h-full w-full place-content-center">
				<h1 className="text-center text-4xl font-bold text-gray-900 dark:text-white">
					{code} - Page not found!
				</h1>
			</div>
		</>
	)
}

export function Embed404() {
	return (
		<>
			<Head>
				<meta name="robots" content="noindex" />
			</Head>
			<NextSeo title={`Hologram not found`} />
			<Linky href={process.env.BASE_URL} target="_blank">
				<div className="lkg-layout flex min-h-[100vh] place-content-center place-items-center rounded-xl">
					<p className="text-xl">Hologram not found</p>
				</div>
			</Linky>
		</>
	)
}
